<template>
  <nav
    class="bg-white dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200 shadow-inner w-full sm:hidden pointer-events-auto"
  >
    <div class="mx-auto px-4">
      <div class="flex h-16">
        <div
          v-for="item in nav"
          :key="item.id"
          class="w-1/3 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-neutral-800 active:text-neutral-900 dark:hover:bg-neutral-900 dark:active:bg-neutral-800 dark:hover:text-neutral-200 dark:active:text-neutral-300"
          :class="{
            'opacity-50 pointer-events-none': item.disabled,
          }"
        >
          <div
            v-if="item.id === 'menu'"
            @click="emit('openNavbar')"
            class="w-full h-full flex flex-col items-center justify-center"
          >
            <div
              v-if="item.icon"
              class="h-6 w-6 flex items-center justify-center rounded-full"
            >
              <component :is="item.icon" />
            </div>
            <!-- <span class="text-xs">{{ item.name }}</span> -->
          </div>
          <TheNuxtLink
            v-else
            :to="item.href"
            class="w-full h-full flex flex-col items-center justify-center"
          >
            <div
              v-if="item.icon"
              class="h-6 w-6 flex items-center justify-center rounded-full"
            >
              <component :is="item.icon" />
            </div>
            <!-- <span class="text-xs">{{ item.name }}</span> -->
          </TheNuxtLink>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { NavigationItem } from "~/types/navigation";

interface Props {
  nav: (NavigationItem & {
    disabled?: boolean;
  })[];
}

interface Events {
  (e: "openNavbar", v: void): void;
}

defineProps<Props>();
const emit = defineEmits<Events>();
</script>
